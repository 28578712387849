// A component for authentication that accepts a username and password
// and sends them to the server for authentication via fetch.
// On success, the user and userToken are set in the UserContext.
// On failure, an error is displayed.

import React, { useContext, useState } from 'react';

import UserContext from '../../context/UserContext';

type App = {
    name: string;
    key: string;
    knack_id?: string;
}

const api = process.env.API_URL || 'https://legacy-api.smallsoftware.app/api/v1';

export const Auth = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [appList, setAppList] = useState<App[]>([]);
    const [app, setApp] = useState<App | null>(null);
    const [appModuleError, setAppModuleError] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        setApp: setAppContext,
        setUser,
        setUserToken
    } = useContext(UserContext)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (!app) {
                // retrieve associated apps for the email address
                setLoading(true);
                setError(false);
                const response = await fetch(`${api}/app/auth/checkemail`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: username }),
                })
                const apps = await response.json();
                if (!apps.length) {
                    throw new Error('No apps found for this email address.')
                }
                const newAppsList = apps.map((app: { organization: { name: string; _id: string; knackAppId: string; }; }) => ({
                    name: app.organization.name,
                    key: app.organization._id,
                    knack_id: app.organization.knackAppId,
                }));
                setAppList(newAppsList);
                setApp(newAppsList[0])
                setLoading(false);
            } else {
                // authenticate the user
                setLoading(true);
                setError(false);
                const response = await fetch(`${api}/app/auth/${app.key}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: username,
                        password,
                    }),
                })
                const { streamChatToken, supabaseUserId, appModules } = await response.json();
                if (!streamChatToken || !supabaseUserId) {
                    throw new Error('Invalid email address or password.')
                }
                if (!appModules.includes('Chat')) {
                    setAppModuleError(true);
                    setLoading(false);
                    return;
                }
                setUser(supabaseUserId);
                setUserToken(streamChatToken);
                setAppContext(app);
                setLoading(false);
            }
        } catch (err) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <div className='auth__form-container'>
            <h1 className='auth__header'>
                Small Software Chat
            </h1>
            <form className='auth__form' onSubmit={handleSubmit}>
                <input
                    className='auth__input'
                    type='text'
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value)
                        setError(false)
                        setAppModuleError(false)
                        setAppList([])
                        setPassword('')
                        setApp(null)
                    }}
                    placeholder='Email Address'
                    required
                />
                {appList.length > 0 && (
                    <select
                        className='auth__input'
                        onChange={(e) => setApp(appList.find((app) => app.key === e.target.value) || null)}
                        value={app?.key || ''}
                        placeholder='Select an app'
                        required
                    >
                        <option value=''>Select an app</option>
                        {appList.map((app) => (
                            <option key={app.key} value={app.key}>{app.name}</option>
                        ))}
                    </select>
                )}
                {app && (
                    <input
                        className='auth__input'
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Password'
                        required
                    />
                )}
                <div className='auth__error-container'>
                    {error && <p className='auth__error'>Invalid email address or password.</p>}
                    {appModuleError && <p className='auth__error'>Your application does not currently support chat.</p>}
                </div>
                <button className='auth__button' type='submit' disabled={loading || appModuleError}>
                    {loading ? 'Loading...' : app ? 'Sign in' : 'Continue'}
                </button>
            </form>
        </div>
    );
};
import { Avatar, useChatContext } from 'stream-chat-react';

import { HashIcon } from './HashIcon';

import type { StreamChatType } from '../../types';

export const EmptyChannel = () => {
    const { channel, client } = useChatContext<StreamChatType>();

    const members = Object.values(channel?.state?.members || {}).filter(
        ({ user }) => user?.id !== client.userID,
    );

    const getAvatarGroup = () => {
        if (!members.length) return <Avatar size={72} />;

        return (
            <div className='channel-empty__avatars'>
                {members.map((member, i) => {
                    if (i > 2) return null;
                    return (
                        <Avatar
                            key={i}
                            image={member.user?.image}
                            name={member.user?.name || member.user?.id}
                            size={72}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className='channel-empty__container'>
            {channel?.type === 'messaging' ? <HashIcon /> : getAvatarGroup()}
            <p className='channel-empty__main-description'>
                This is the beginning of your chat history.
            </p>
            <p className='channel-empty__secondary-description'>Send messages, attachments, links, emojis, and more.</p>
        </div>
    );
};

import { AdminPanelHeader } from './AdminPanelHeader';
import { AdminPanelFooter } from './AdminPanelFooter';
import { ChannelNameInputField } from './ChannelNameInputField';
import { UserList } from './UserList';

import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { useWorkspaceController } from '../../context/WorkspaceController';
import {ChannelUserInputField} from "./ChannelUserInputField";

export const CreateChannel = () => {
    const { closeAdminPanel } = useWorkspaceController();
    const { createChannelType, userQuery, name, handleUserQueryChange, handleInputChange, handleSubmit, errors } = useAdminPanelFormState();


    return (
        <div className='admin-panel__form'>
            <AdminPanelHeader onClose={closeAdminPanel}
                title={createChannelType === 'messaging'
                    ? 'Create a New Channel'
                    : 'Send a Direct Message'}
            />
            <div className={"admin-panel__form-inputs"}>
                <ChannelUserInputField
                    error={errors.name}
                    name={userQuery}
                    onChange={handleUserQueryChange}
                    placeholder='Search by name' />
                {createChannelType === 'messaging' &&
                    <ChannelNameInputField
                        error={errors.name}
                        name={name}
                        onChange={handleInputChange}
                        placeholder='channel-name (no spaces)' />
                }
            </div>
            <UserList />
            <AdminPanelFooter
                onButtonClick={handleSubmit}
                buttonText={createChannelType === 'messaging'
                    ? 'Create Channel'
                    : 'Create Message Group'}
            />
        </div>
    );
};

import { ChangeEventHandler } from 'react';
import { ValidationError } from './ValidationError';

type ChannelNameInputProps = {
  name: string;
  error: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
};

export const ChannelUserInputField = ({ name = '', placeholder = 'channel-name', onChange }: ChannelNameInputProps) => {
  return (
    <div className='channel-name-input-field'>
      <h2><span>Search Users</span></h2>
      <input onChange={onChange} placeholder={placeholder} type='text' value={name} />
    </div>
  );
};
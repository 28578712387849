import { MouseEventHandler } from 'react';
import buttonImage from '../../assets/button-moderatemode.png'

type AddModeratorButtonProps = {
    onClick: MouseEventHandler<HTMLButtonElement>;
    moderatorMode: boolean
}

export const ModeratorButton = (
    { onClick, moderatorMode
    }: AddModeratorButtonProps,
) => (
    !moderatorMode ? <button onClick={onClick} className='moderator-button'>
        <img src={buttonImage} alt="Moderator Mode Button" className='moderator-button-image' />
    </button> :
        <button onClick={onClick} className='moderator-button'>
            <p>{'< < back to regular mode'}</p>
        </button>


);
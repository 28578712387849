import { ChannelList } from 'stream-chat-react';

import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { ModeratorButton } from '../ModeratorButton/ModeratorButton'
import { ChannelPreview } from '../ChannelPreview/ChannelPreview';

import { CompanyLogo } from './icons';

import type { Channel, ChannelFilters } from 'stream-chat';
import { ChannelSort } from 'stream-chat';

import { StreamChatType } from '../../types';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/UserContext';
const options = { state: true, watch: true, presence: true, limit: 30 };
const sort: ChannelSort<StreamChatType> = { last_message_at: -1, updated_at: -1 };

const api = process.env.API_URL || 'https://legacy-api.smallsoftware.app/api/v1';

const FakeCompanySelectionBar = () => {
    return (
        <div className='sidebar__company-selection-bar'>
            <div className='sidebar_company-logos'>
                <a className='sidebar__company-badge' href="https://smallsoftware.com" target="_blank" rel="noreferrer">
                    <span className="sr-only">Small Software</span>
                    <CompanyLogo />
                </a>
            </div>
            <div>
                <button
                    className='sidebar__signout-button'
                    onClick={() => {
                        localStorage.removeItem('user');
                        localStorage.removeItem('userToken');
                        localStorage.removeItem('app');
                        window.location.reload();
                    }}
                >
                    Sign Out
                </button>
            </div>
        </div>
    )
}

const customChannelTeamFilter = (channels: Channel[]) => {
    return channels.filter((channel) => channel.data?.name?.includes("#"));
};

const customChannelMessagingFilter = (channels: Channel[]) => {
    return channels.filter((channel) => !channel.data?.name?.includes("#"));
};

const TeamChannelsList = () => {
    const { user, moderatorMode, streamTeam } = useContext(UserContext);
    const filter: ChannelFilters[] = [
        { type: 'messaging', hidden: false, members: { $in: [user] } },
        { type: 'messaging', hidden: false, team: { $in: [streamTeam] } },
    ];

    return (
        <ChannelList
            channelRenderFilterFn={customChannelTeamFilter}
            filters={moderatorMode ? filter[1] : filter[0]}
            options={options}
            sort={sort}
            EmptyStateIndicator={() => (
                <div className='channel-list__empty-state'>
                    <p className='channel-list__empty-state__text'>
                        No channels found
                    </p>
                </div>
            )}
            List={(listProps) => (
                <TeamChannelList
                    {...listProps}
                    type='messaging'
                />
            )}
            Preview={(previewProps) => (
                <ChannelPreview
                    {...previewProps}
                    type='messaging'
                />
            )}
        />
    )
};

const MessagingChannelsList = () => {
    const { user, moderatorMode, streamTeam } = useContext(UserContext);
    const filter: ChannelFilters[] = [
        { type: 'messaging', hidden: false, members: { $in: [user] } },
        { type: 'messaging', hidden: false, team: { $in: [streamTeam] } },
    ];

    return (
        <ChannelList
            channelRenderFilterFn={customChannelMessagingFilter}
            filters={moderatorMode ? filter[1] : filter[0]}
            options={options}
            sort={sort}
            setActiveChannelOnMount={false}
            EmptyStateIndicator={() => (
                <div className='channel-list__empty-state'>
                    <p className='channel-list__empty-state__text'>
                        No Direct Messages found
                    </p>
                </div>
            )}
            List={(listProps) => (
                <TeamChannelList
                    {...listProps}
                    type='team'
                />
            )}
            Preview={(previewProps) => (
                <ChannelPreview
                    {...previewProps}
                    type='team'
                />
            )}
        />
    )
}

export const Sidebar = () => {
    const { app, moderatorMode, setModeratorMode, userIsModerator } = useContext(UserContext);
    const [logoUrl, setLogoUrl] = useState<string | null>(null);

    useEffect(() => {
        const fetchLogo = async () => {
            const response = fetch(`${api}/app/organizations/${app?.knack_id}/about`);
            const data = await (await response).json();
            if (data["Company Logo"]?.url) setLogoUrl(data["Company Logo"].url);
        }
        if (app?.key) {
            fetchLogo();
        }
    }, [app]);

    const checkModeratorButtonVisibility = () => {
        if (userIsModerator === true) {
            return true
        } else return false
    }

    const handleModeratorButtonClick = () => {
        if (moderatorMode) {
            setModeratorMode(!moderatorMode)
            window.location.reload()
        }

        if (!moderatorMode) {
            setModeratorMode(!moderatorMode)
        }
    }

    return (
        <div className='sidebar'>
            <FakeCompanySelectionBar />
            <div className='channel-list-bar'>
                <div className='channel-list-bar__header'>
                    <div className='sidebar__app-logo'>
                        {logoUrl && <img src={logoUrl} alt={`${app?.name} logo`} />}
                    </div>
                    {!logoUrl && <p className='channel-list-bar__header__text'>{app?.name || 'Small Software'}</p>}
                </div>
                <TeamChannelsList />
                <MessagingChannelsList />
                <div className='moderator-button-container'>
                    {checkModeratorButtonVisibility() && <ModeratorButton onClick={handleModeratorButtonClick} moderatorMode={moderatorMode} />}
                </div>
            </div>
        </div>
    );
};
import {
    ChangeEventHandler,
    createContext,
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Workspace } from '../../../context/WorkspaceController';
import { useChatContext } from 'stream-chat-react';
import { StreamChatType } from '../../../types';

type UpsertChannelParams = { name: string, members: string[] };

type ChannelType = 'messaging';

type UpsertAction = 'create' | 'update';

export type FormValues = {
    name: string;
    members: string[];
    userQuery: string;
};

export type FormErrors = {
    name: string | null;
    members: string | null;
};

type AdminPanelFormContext = FormValues & {
    handleInputChange: ChangeEventHandler<HTMLInputElement>;
    handleUserQueryChange: ChangeEventHandler<HTMLInputElement>;
    handleMemberSelect: ChangeEventHandler<HTMLInputElement>;
    handleSubmit: MouseEventHandler<HTMLButtonElement>;
    createChannelType?: ChannelType;
    errors: FormErrors;
};


const Context = createContext<AdminPanelFormContext>({
    handleInputChange: () => null,
    handleUserQueryChange: () => null,
    handleMemberSelect: () => null,
    handleSubmit: () => null,
    members: [],
    name: '',
    userQuery: '',
    errors: { name: null, members: null },
});


type AdminPanelFormProps = {
    workspace: Workspace;
    onSubmit: () => void;
    defaultValues: FormValues;
}

const getChannelTypeFromWorkspaceName = (workspace: Workspace): ChannelType | undefined => (
    workspace.match(/.*__(messaging)/)?.[1] as ChannelType | undefined
);

const getUpsertAction = (workspace: Workspace): UpsertAction | undefined => {
    if (workspace.match('Channel-Create')) return 'create';
    if (workspace.match('Channel-Edit')) return 'update';
};

export const AdminPanelForm = ({ children, defaultValues, workspace, onSubmit }: PropsWithChildren<AdminPanelFormProps>) => {
    const { client, channel, setActiveChannel } = useChatContext<StreamChatType>();
    const [name, setChannelName] = useState<string>(defaultValues.name);
    const [userQuery, setUserQuery] = useState<string>(defaultValues.userQuery)
    const [members, setMembers] = useState<string[]>(defaultValues.members);
    const [errors, setErrors] = useState<FormErrors>({ name: null, members: null });

    const createChannelType = getChannelTypeFromWorkspaceName(workspace);
    const action = getUpsertAction(workspace);

    const createChannel = useCallback(async ({ name, members }: UpsertChannelParams) => {
        console.log(name, members)
        if ( members.length === 0) return;

        if(!name) {
            const newDM = client.channel('messaging',
                null,
                {
                members,
                team: client.user!.teams![0],
            })
            await newDM.watch();
            console.log(newDM);
            setActiveChannel(newDM);
        } else {
            const newChannel = client.channel(
                "messaging",
                `${client.user!.teams![0]}_${name}`,
                {
                    name: '#' + name,
                    members,
                    team: client.user!.teams![0],
                });
            await newChannel.watch();
            setActiveChannel(newChannel);
        }
    }, [setActiveChannel, client]);

    const updateChannel = useCallback(async ({ name, members }: UpsertChannelParams) => {
        if (name !== (channel?.data?.name || channel?.data?.id)) {
            await channel?.update(
                { name },
                { text: `Channel name changed to ${name}` },
            );
        }

        if (members?.length) {
            await channel?.addMembers(members);
        }
    }, [channel]);

    const validateForm = useCallback(({ action, createChannelType, values }: { values: FormValues, createChannelType?: ChannelType, action?: UpsertAction }): FormErrors | null => {
        let errors: FormErrors = { name: null, members: null };

        if (action === 'create') {
            errors = {
                name: !values.name && createChannelType === 'messaging' ? 'Channel name is required' : null,
                members: values.members.length < 2 ? 'At least one additional member is required' : null,
            };
        }

        if (action === 'update' && values.name === defaultValues.name && values.members.length === 0) {
            errors = {
                name: 'Name not changed (change name or add members)',
                members: 'No new members added (change name or add members)',
            };
        }

        return Object.values(errors).some(v => !!v) ? errors : null;
    }, [defaultValues.name]);

    const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(async (event) => {
        event.preventDefault();
        const errors = validateForm({ values: { name, members, userQuery }, action, createChannelType });

        if (errors) {
            setErrors(errors);
            return;
        }

        try {
            if (action === 'create') await createChannel({ name, members });
            if (action === 'update') await updateChannel({ name, members });
            onSubmit();
        } catch (err) {
            console.error(err);
        }
    }, [userQuery, action, createChannelType, name, members, createChannel, updateChannel, onSubmit, validateForm]);

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        event.preventDefault();
        const channelName = event.target.value.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
        setChannelName(channelName);
    }, []);

    const handleUserQueryChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        event.preventDefault();
        const userQuery = event.target.value || '';
        setUserQuery(userQuery);
    }, []);

    const handleMemberSelect: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        setMembers((prevMembers) => {
            const { value } = event.target;
            if (event.target.checked) {
                return prevMembers.length ? [...prevMembers, value] : [value];
            }
            return prevMembers?.filter((prevUser) => prevUser !== value);
        });
    }, []);

    useEffect(() => {
        setChannelName(defaultValues.name);
        setMembers(defaultValues.members)
    }, [defaultValues, createChannelType]);

    return (
        <Context.Provider value={{
            createChannelType,
            errors,
            name,
            userQuery,
            members,
            handleInputChange,
            handleUserQueryChange,
            handleMemberSelect,
            handleSubmit,
        }}>
            {children}
        </Context.Provider>
    );
};

export const useAdminPanelFormState = () => useContext(Context);
import { createContext } from "react";

type UserContextType = {
    app: {
        name: string;
        key: string;
        knack_id?: string;
    } | null;
    setApp: (app: {
        name: string;
        key: string;
        knack_id?: string;
    }) => void;
    user: string | null;
    setUser: (user: string) => void;
    userToken: string | null;
    setUserToken: (userToken: string) => void;
    moderatorMode: boolean;
    setModeratorMode: (moderatorMode: boolean) => void;
    streamTeam: string | null;
    userRole: string | null;
    setUserRole: (userRole: string) => void,
    userIsModerator: boolean | null
};


const UserContext = createContext<UserContextType>({
    app: null,
    setApp: () => { },
    user: null,
    setUser: () => { },
    userToken: null,
    setUserToken: () => { },
    moderatorMode: false,
    setModeratorMode: () => { },
    streamTeam: null,
    userRole: null,
    setUserRole: () => { },
    userIsModerator: false
});

export default UserContext;
import { useEffect, useMemo, useState } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';

import type { UserResponse } from 'stream-chat';

import type { StreamChatType } from '../../types';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { ValidationError } from './ValidationError';
import { StreamUserSearch } from '../../submodules/s2-chat-compose-search/src';
import {ListHeader} from "./ListHeader";

const ListContainer = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const { errors, createChannelType } = useAdminPanelFormState()
    const showHeading = !createChannelType || createChannelType === 'messaging';
    return (
        <div className='user-list__container'>
            {showHeading && <h2><span>Add Members</span><ValidationError errorMessage={errors.members} /></h2>}
            {children}
        </div>
    );
};

type UserItemProps = {
    user: UserResponse<StreamChatType>;
    selected?: boolean;
};

const UserItem = ({ user, selected = false }: UserItemProps) => {
    const { handleMemberSelect } = useAdminPanelFormState();

    // @ts-ignore
    const {name, _location, _job_title} = user

    return (
        <label htmlFor={user.id} title={name} className='user-list__row'>
            <div className='user-list__column-block'>
                <div className='user-list__column--user-data'>
                    <Avatar image={user.image} name={name} size={32} />
                    <p className='user-item__name'>{name}<br/>
                        <small>{_location} • {_job_title}</small>
                    </p>
                </div>
            </div>
            <div className='user-list__column--checkbox'>
                <input type='checkbox' name='members' id={user.id} value={user.id} onChange={handleMemberSelect} checked={selected} />
            </div>
        </label>
    );
};


type UserListLoadState = 'loading' | 'error' | 'empty';

const LOAD_STATE_NOTIFICATION: Record<UserListLoadState, string> = {
    empty: 'No users found.',
    error: 'Error loading, please refresh and try again.',
    loading: 'Loading users...',
};


export const UserList = () => {
    const { client, channel } = useChatContext<StreamChatType>();
    const { members, userQuery, createChannelType } = useAdminPanelFormState();
    const [loadState, setLoadState] = useState<UserListLoadState | null>(null);
    const [hrContacts, setHRContacts] = useState<UserResponse<StreamChatType>[] | undefined>();
    const [managerContacts, setManagerContacts] = useState<UserResponse<StreamChatType>[] | undefined>();
    const [locationContacts, setLocationContacts] = useState<UserResponse<StreamChatType>[] | undefined>();
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>()

    const channelMembers = useMemo(() => channel?.state.members
        ? Object.keys(channel.state.members)
        : [],
        [channel?.state?.members],
    );

    useEffect(() => {
        const getUsers = async () => {
            setLoadState('loading');

            try {
                console.log("Searching with", userQuery)
                const searchClient = new StreamUserSearch(client);
                const hrContactResults = await searchClient.getHRContacts(userQuery);
                const locationContactResults = await searchClient.getLocationContacts(userQuery);
                const managerContactResults = await searchClient.getManagerContacts(userQuery);

                if (!hrContactResults.length &&
                    !locationContactResults.length &&
                    !managerContactResults.length) {
                    setLoadState('empty');
                    return;
                } else {
                    setHRContacts(hrContactResults)
                    setLocationContacts(locationContactResults)
                    setManagerContacts(managerContactResults)
                    setLoadState(null);
                }
            } catch (event) {
                setLoadState('error');
                console.log(event)
            }
        };

        if(searchTimeout) {
            clearTimeout(searchTimeout)
        }

        // Set a new timeout
        const timeout = setTimeout(() => {
            console.log("calling timeout")
            getUsers();
        }, 250);

        // Update the searchTimeout state
        setSearchTimeout(timeout);
    }, [client, channelMembers, createChannelType, userQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ListContainer>
            {loadState
                ? <div className='user-list__message'>{LOAD_STATE_NOTIFICATION[loadState]}</div>
                : <>
                    {hrContacts && hrContacts.length > 0 &&
                        <>
                            <ListHeader title={"HR Contacts"}/>
                            {hrContacts.map((user) => <UserItem key={user.id} user={user} selected={members.includes(user.id)} />)}
                        </>
                    }
                    {managerContacts && managerContacts.length > 0 &&
                        <>
                            <ListHeader title={"Manager Contacts"}/>
                            {managerContacts.map((user) => <UserItem key={user.id} user={user} selected={members.includes(user.id)} />)}
                        </>
                    }
                    {locationContacts && locationContacts.length > 0 &&
                        <>
                            <ListHeader title={"Location Contacts"}/>
                            {locationContacts.map((user) => <UserItem key={user.id} user={user} selected={members.includes(user.id)} />)}
                        </>
                    }
                </>
            }
        </ListContainer>
    );
};

export const ListHeader = ({title} : {title: string}) => {
    return (
        <div className='user-list__row header'>
            <div className='user-list__column-block'>
                <div className='user-list__column--user-data'>
                    <p className='user-item__name'>{title}</p>
                </div>
            </div>
        </div>
    );
};

import { useEffect, useState } from "react";
import { UserResponse } from "stream-chat";

export default function useComposeEnabled(user: UserResponse | undefined, type: string) {
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        if (type === 'team') {
            // DMs
            if (user?._admin === true) {
                setEnabled(true);
            } else if (user?._chat_options === "Crew to Crew Enabled (across locations)") {
                setEnabled(true);
            } else if (user?._chat_options === "Crew to Crew Enabled (within location)") {
                setEnabled(true);
            } else if (user?._chat_options === "Crew to Crew Disabled (allow Office)") {
                if (user?.location === "Office") {
                    setEnabled(true);
                } else {
                    setEnabled(false);
                }
            } else if (user?._chat_options === "Crew to Crew Disabled (allow Office and Managers)") {
                if (
                    user?.location === "Office" ||
                    (Array.isArray(user?._manager_for_locations) && user?._manager_for_locations?.length > 0)
                ) {
                    setEnabled(true);
                } else {
                    setEnabled(false);
                }
            } else {
                setEnabled(false);
            }
        }
        else {
            const userIsAdmin = user?._admin
            const userIsHR = user?._hr
            const userIsManager = user?._manager_for_locations && Array.isArray(user?._manager_for_locations) && user?._manager_for_locations?.length > 0
            
            const canCreateChannels = userIsAdmin || userIsHR || userIsManager
            setEnabled(!!canCreateChannels);
        }
    }, [type, user])

    return enabled;
}
import { useEffect, useState } from 'react';
import { OwnUserResponse, StreamChat } from 'stream-chat';
import { Chat, enTranslations, Streami18n } from 'stream-chat-react';

import { ChannelContainer } from './components/ChannelContainer/ChannelContainer';
import { Sidebar } from './components/Sidebar/Sidebar';

import { WorkspaceController } from './context/WorkspaceController';
import UserContext from './context/UserContext';

import type { StreamChatType } from './types';
import { Auth } from './components/Auth/Auth';

const apiKey = process.env.REACT_APP_STREAM_KEY;
const theme = 'light';

const i18nInstance = new Streami18n({
    language: 'en',
    translationsForLanguage: {
        ...enTranslations,
    },
});

const client = StreamChat.getInstance<StreamChatType>(apiKey!, { enableInsights: true, enableWSFallback: true });

const App = () => {
    const [user, setUser] = useState(localStorage.getItem('user') || null);
    const [userToken, setUserToken] = useState(localStorage.getItem('userToken') || null);
    const [connectionId, setConnectionId] = useState<string | null>(null);
    const [app, setApp] = useState<{ key: string, name: string, knack_id?: string } | null>(localStorage.getItem('app') ? JSON.parse(localStorage.getItem('app')!) : null);
    const [moderatorMode, setModeratorMode] = useState(false)
    const [streamTeam, setStreamTeam] = useState('')
    const [userRole, setUserRole] = useState('')
    const [userIsModerator, setUserIsModerator] = useState(false)

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', user);
        } else {
            localStorage.removeItem('user');
        }
        if (userToken) {
            localStorage.setItem('userToken', userToken);
        } else {
            localStorage.removeItem('userToken');
        }
        if (app) {
            localStorage.setItem('app', JSON.stringify(app));
        } else {
            localStorage.removeItem('app');
        }
    }, [user, userToken, connectionId, app]);

    useEffect(() => {
        const connectUser = async () => {
            try {
                if (user && userToken) {
                    const connection = await client.connectUser({ id: user! }, userToken)
                    if (connection) {
                        setConnectionId(connection.connection_id || '');
                        const userInfo = connection.me as OwnUserResponse & { _moderator?: boolean }
                        const _moderator = userInfo?._moderator
                        const teams: string[] | undefined = connection.me?.teams
                        const currentUserRole: string | undefined = connection.me?.role

                        if (teams && teams.length > 0) {
                            setStreamTeam(teams[0])
                        }

                        if (currentUserRole) {
                            setUserRole(currentUserRole)
                        }

                        if (_moderator) {
                            setUserIsModerator(_moderator)
                        }

                    }
                }
            } catch (e: any) {
                console.log(e.message)
            }
        }

        if (user && userToken) {
            connectUser();
            return () => {
                client.disconnectUser();
            }
        }
    }, [user, userToken]);

    useEffect(() => {
        const handleColorChange = (color: string) => {
            const root = document.documentElement;
            if (color.length && color.length === 7) {
                root.style.setProperty('--primary-color', `${color}E6`);
                root.style.setProperty('--primary-color-alpha', `${color}1A`);
            }
        };

        window.addEventListener('message', (event) => handleColorChange(event.data));
        return () => {
            client.disconnectUser();
            window.removeEventListener('message', (event) => handleColorChange(event.data));
        };
    }, []);

    return (
        <UserContext.Provider value={{ app, setApp, user, setUser, userToken, setUserToken, moderatorMode, setModeratorMode, streamTeam, userRole, setUserRole, userIsModerator }}>
            <div className='app__wrapper str-chat'>
                {(!user || !userToken || !connectionId) && (
                    <Auth />
                )}
                {user && userToken && connectionId && (
                    <Chat {...{ client, i18nInstance }} theme={`team ${theme}`}>
                        <WorkspaceController>
                            <Sidebar />
                            <ChannelContainer />
                        </WorkspaceController>
                    </Chat>
                )}
            </div>
        </UserContext.Provider>
    );
};

export default App;

export const CompanyLogo = () => (
    <svg width="505" height="525" viewBox="0 0 505 525" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="110" cy="109" rx="110" ry="109" transform="matrix(-1 0 0 1 460 307)" fill="url(#paint0_linear_878_5464)" fillOpacity="0.4" />
        <ellipse cx="138.5" cy="136.5" rx="138.5" ry="136.5" transform="matrix(-1 0 0 1 295 1)" fill="url(#paint1_linear_878_5464)" fillOpacity="0.13" />
        <ellipse cx="46.5" cy="46" rx="46.5" ry="46" transform="matrix(-1 0 0 1 185 351)" fill="url(#paint2_linear_878_5464)" fillOpacity="0.13" />
        <ellipse cx="46.5" cy="46" rx="46.5" ry="46" transform="matrix(-1 0 0 1 93 0)" fill="url(#paint3_linear_878_5464)" fillOpacity="0.13" />
        <ellipse cx="70" cy="69" rx="70" ry="69" transform="matrix(-1 0 0 1 505 112)" fill="url(#paint4_linear_878_5464)" fillOpacity="0.2" />
        <path d="M153.14 331.7C165.038 339.568 185.993 347.707 206.138 347.707C235.341 347.707 249.132 333.057 249.132 314.744C249.132 295.483 237.64 284.902 208.031 273.915C168.282 259.672 149.49 237.697 149.49 211.11C149.49 175.3 178.287 146 225.877 146C248.32 146 267.924 152.375 280.228 159.7L270.223 189C261.57 183.574 245.481 176.114 224.931 176.114C201.136 176.114 187.886 189.814 187.886 206.363C187.886 224.675 201.136 232.95 229.933 243.937C268.33 258.587 287.934 277.849 287.934 310.811C287.934 349.741 257.784 377.278 205.192 377.278C180.991 377.278 158.548 371.309 143 362.221L153.005 331.429L153.14 331.7Z" fill="#C40C60" />
        <circle cx="334.189" cy="351.066" r="26.2115" fill="#C40C60" />
        <defs>
            <linearGradient id="paint0_linear_878_5464" x1="-50.7692" y1="218" x2="181.926" y2="53.9019" gradientUnits="userSpaceOnUse">
                <stop offset="0.157442" stopColor="#FC55A1" />
                <stop offset="1" stopColor="#F95CA3" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_878_5464" x1="-63.9231" y1="273" x2="228.006" y2="66.0124" gradientUnits="userSpaceOnUse">
                <stop offset="0.157442" stopColor="#FC55A1" />
                <stop offset="1" stopColor="#F95CA3" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_878_5464" x1="-21.4615" y1="92" x2="76.7954" y2="22.5922" gradientUnits="userSpaceOnUse">
                <stop offset="0.157442" stopColor="#FC55A1" />
                <stop offset="1" stopColor="#F95CA3" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint3_linear_878_5464" x1="-21.4615" y1="92" x2="76.7954" y2="22.5922" gradientUnits="userSpaceOnUse">
                <stop offset="0.157442" stopColor="#FC55A1" />
                <stop offset="1" stopColor="#F95CA3" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint4_linear_878_5464" x1="-32.3077" y1="138" x2="115.253" y2="33.3908" gradientUnits="userSpaceOnUse">
                <stop offset="0.157442" stopColor="#FC55A1" />
                <stop offset="1" stopColor="#F95CA3" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>

);